import { Component, OnInit, OnChanges, EventEmitter, Output, Input, OnDestroy, SimpleChanges, SimpleChange } from "@angular/core";
import { UntypedFormGroup, FormControl, FormGroupDirective, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { Subscription } from "rxjs";
import { SearchCustomerQuery } from "@domain/models/filters/search-customer-query.model";
import { CustomerService } from "@domain/services/customer.service";
import { LoadingHelper } from "@shared/helpers/loading.helper";
import { CustomerFilterItem } from "@shared/components/customer-filter/customer-filer-item";
import { CustomerSearchComponent } from "@shared/components/customer-search/customer-search.component";
import { CustomerPart } from "@domain/models/customer-part.model";
import { DialogService } from '@core/services/dialog.service';
import { FilterMode } from "./filter-mode.enum";

@Component({
  selector: "app-customer-filter",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, CustomerSearchComponent],
  templateUrl: "./customer-filter.component.html",
  styleUrl: "./customer-filter.component.scss"
})
export class CustomerFilterComponent implements OnInit, OnDestroy, OnChanges {
  
  @Input() showToAllCustomers = true;
  @Input() showToCustomerPartIds: number[] = [];

  @Output() showToAllCustomersChanged = new EventEmitter<boolean>();
  @Output() showToCustomersChanged = new EventEmitter<CustomerFilterItem[]>();
  @Output() loadingChanged = new EventEmitter<boolean>();

  FilterMode = FilterMode; // Gör enum tillgänglig i HTML

  private loadingHelper = new LoadingHelper();

  private componentSubscriptions = new Array<Subscription>();

  formGroup: UntypedFormGroup;
  private filterModeFromControl: FormControl<FilterMode>;  
  private customerFormControl: FormControl<CustomerPart>;

  validationCustomerMessage: string;

  selectedCustomers: CustomerFilterItem[] = [];

  constructor(private parentFormGroup: FormGroupDirective = null,
    private customerService: CustomerService,
    private dialogService: DialogService) {
    
  }

  isFormInitialized: boolean = false;

  ngOnInit() {
    this.filterModeFromControl = new FormControl<FilterMode>(FilterMode.All);
    this.customerFormControl = new FormControl<CustomerPart>(null);

    this.filterModeFromControl.valueChanges.subscribe(_ => {      
      this.onFilterModeChanged();      
    });

    this.customerFormControl.valueChanges.subscribe(newValue => {
      if (newValue){
        this.onCustomerSelected(newValue);
      }
    });

    this.formGroup = new UntypedFormGroup({
      filterMode: this.filterModeFromControl,
      customer: this.customerFormControl
    });

    if (this.parentFormGroup != null) {
      this.parentFormGroup.control.addControl("formGroup", this.formGroup);
    }

    this.isFormInitialized = true;
  }

  ngOnChanges(changes: SimpleChanges) {

    for (const propertyName in changes) {
      const changedProperty = changes[propertyName];

      this.updateInputProperty(changedProperty, propertyName);
    }
  }

  private updateInputProperty(propertyChange: SimpleChange, propertyName: string): void {

    switch (propertyName) {
      case "showToAllCustomers": {

        const showToAllCustomersNewValue = propertyChange.currentValue as boolean;

        if (this.isFormInitialized) {
          this.filterModeFromControl.setValue(showToAllCustomersNewValue ? FilterMode.All : FilterMode.Selected);
        }

        break;
      }
      case "showToCustomerPartIds": {

        const showToCustomerPartIdsChange = propertyChange.currentValue as number[];

        this.getSelectedCustomers(showToCustomerPartIdsChange);

        break;
      }
    }
  }

  ngOnDestroy(): void {
    this.componentSubscriptions.forEach(component => {
      component.unsubscribe();
    });
    this.componentSubscriptions.splice(0);
  }

  validate(): boolean {
    if (this.filterModeFromControl.value == FilterMode.Selected && this.selectedPartIds.length === 0) {
      this.validationCustomerMessage = "Minst ett företag/organisation måste väljas";
      return false;
    }   

    this.validationCustomerMessage = null;
    return true;
  }

  private onFilterModeChanged() {
    this.selectedCustomers = [];
    this.validationCustomerMessage = null;    

    this.emitShowToAllCustomersChanged(FilterMode.All ? true : false);
    this.emitShowToCustomersChanged(this.selectedCustomers);
  }  

  private get selectedPartIds(): number[] {
    return this.selectedCustomers.map(customer => customer.partId);
  }

  unselectCustomer(customerId: number,) {
    const index = this.selectedCustomers.findIndex(x => x.customerId === customerId);
    this.selectedCustomers.splice(index, 1);
    this.emitShowToCustomersChanged(this.selectedCustomers);
  }

  private getSelectedCustomers(customerPartIds: number[]) {
    if (customerPartIds && customerPartIds.length === 0) {
      return;
    }

    this.loadingHelper.startLoading();

    const searchQuery = new SearchCustomerQuery();
    searchQuery.searchText = null;
    searchQuery.pageSize = customerPartIds.length;
    searchQuery.partIds = customerPartIds;

    this.customerService.searchCustomers(searchQuery).subscribe({
      next: data => {
        this.selectedCustomers.splice(0);
        data.result.forEach(customerPart => {
          this.selectedCustomers.push(new CustomerFilterItem(customerPart));
        });

        this.emitShowToCustomersChanged(this.selectedCustomers);
      },
      error: (error: any) => {
        this.loadingHelper.stopLoading();
        this.dialogService.showError(error, "Hämta valda företag/organisationer");
      },
      complete: () => {
        this.loadingHelper.stopLoading();
      }
    });
  }

  get currentFilterMode(): FilterMode {
    if (this.filterModeFromControl) {
      return this.filterModeFromControl.value;
    }

    return null;
  }

  private emitShowToCustomersChanged(customerFilterItems: CustomerFilterItem[]) {
    this.showToCustomersChanged.emit(customerFilterItems);
  }

  private emitShowToAllCustomersChanged(showAllCustomers: boolean) {
    this.showToAllCustomersChanged.emit(showAllCustomers);
  }

  private emitLoadingChanged(loading: boolean) {
    this.loadingChanged.emit(loading);
  }

  private onCustomerSelected(customerPart: CustomerPart) {
    this.validationCustomerMessage = null;

    if (!customerPart) {
      return;
    }

    const customerFilterItem = new CustomerFilterItem();
    customerFilterItem.customerId = customerPart.customerId;
    customerFilterItem.partId = customerPart.partId;
    customerFilterItem.name = customerPart.name;
    customerFilterItem.organizationNumber = customerPart.organizationNumber;

    this.selectedCustomers.push(customerFilterItem);

    this.emitShowToCustomersChanged(this.selectedCustomers);
  }
  get excludePartIds(): number[] {
    return this.selectedCustomers.map(s => s.partId);
  }
}
