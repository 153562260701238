import { BusinessSystemPerson } from "../business-system-person.model";
import { FrameAgreement } from "../frame-agreement.model";
import { AuthwayOrganizationInfo } from "../organization/authway-organization-info.model";
import { UserOrganizationInfo } from "./user-organization-info.model";

export class FleetWebUserDetails  {
  constructor(jsonObject: any = null) {
    if(jsonObject == null){
      return;
    }

    this.userId = jsonObject.userId;
    this.userName = jsonObject.userName;
    this.lastLoginDate = jsonObject.lastLoginDate != null ? new Date(jsonObject.lastLoginDate) : null;      
    this.firstName = jsonObject.firstName;
    this.lastName = jsonObject.lastName;
    this.createdAtUtc = jsonObject.createdAtUtc ? new Date(jsonObject.createdAtUtc) : null;

    this.businessSystemPerson = jsonObject.businessSystemPerson ? new BusinessSystemPerson(jsonObject.businessSystemPerson) : null;    
    this.organization = jsonObject.organization ? new AuthwayOrganizationInfo(jsonObject.organization) : null;
    this.frameAgreement = jsonObject.frameAgreement ? new FrameAgreement(jsonObject.frameAgreement): null;
    this.groups = jsonObject.groups ? jsonObject.groups.map(g => g) : new Array<string>();
    this.canSendInvitationLink = jsonObject.canSendInvitationLink;
    this.userOrganizationInfo = jsonObject.userOrganizationInfo ? jsonObject.userOrganizationInfo.map(userOrganization => new UserOrganizationInfo(userOrganization)) : new Array<UserOrganizationInfo>();

    this.deletedInAuthway = jsonObject.deletedInAuthway;
    this.isFleetManagerInFleetSetup = jsonObject.isFleetManagerInFleetSetup;
  }

  userId: string;
  userName: string;
  lastLoginDate: Date;
  firstName: string;
  lastName: string;
  createdAtUtc: Date;

  businessSystemPerson: BusinessSystemPerson;
  organization: AuthwayOrganizationInfo;
  frameAgreement: FrameAgreement;
  groups: Array<string>;    
  canSendInvitationLink: boolean;
  userOrganizationInfo: Array<UserOrganizationInfo> = new Array<UserOrganizationInfo>(); 

  deletedInAuthway: boolean;
  isFleetManagerInFleetSetup: boolean;

  get hasConflictingFrameAgreements(){
    return this.businessSystemPerson?.quotaAccountId != null && this.frameAgreement?.quotaAccountId != null && this.frameAgreement?.quotaAccountId !== this.businessSystemPerson.quotaAccountId;
  }

  get isConnected(): boolean{
    return this.businessSystemPerson !== null;
  }
  
}

