import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '@core/services/dialog.service';
import { HeroModel } from '@domain/models/hero.model';
import { HeroService } from '@domain/services/hero.service';
import { LoadingHelper } from '@shared/helpers/loading.helper';
import { map, merge, Subscription } from 'rxjs';
import { CustomValidators } from '@shared/validators/custom-validators';
import { UserService } from '@domain/services/user.service';
import { FleetWebUserDetails } from '@domain/models/user/fleetweb-user-details.model';
import { UpdateFleetWebUserRequest } from '@domain/models/user/update-fleetweb-user-request.model';
import { AuthwayUserGroup } from '@domain/models/user/authway-user-group.model';
import { DeleteFleetWebUserRequest } from '@domain/models/user/delete-fleetweb-user-request.model';
import { BusinessSystemPerson } from '@domain/models/business-system-person.model';
import { BusinessSystemPersonInfoComponent } from '../business-system-person-info/business-system-person-info.component';
import { AuthenticationService } from '@core/services/authentication.service';
import { FleetWebUserGroupRequest } from '@domain/models/user/fleetweb-user-groups-request.model';
import { FleetWebUserRequest } from '@domain/models/user/fleetweb-user-request.model';
import { Permission } from '@core/models/permissions.enum';
import { ValidationErrorResponse } from '@core/models/validation-error-response.model';
import { AuthwayUserEditorComponent } from '../authway-user-editor/authway-user-editor.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit, OnDestroy {
  @ViewChild(AuthwayUserEditorComponent) authwayUserEditorComponent: AuthwayUserEditorComponent;
  @ViewChild(BusinessSystemPersonInfoComponent) businessSystemPersonInfoComponent: BusinessSystemPersonInfoComponent;

  heroModel: HeroModel;
  formGroup: FormGroup;
  groupsFormArray: FormArray<FormControl<boolean>>;

  private _userId: string = null;
  private _user: FleetWebUserDetails;
  private _tenantMissingGroups = false;
  private _groups = new Array<AuthwayUserGroup>();

  private _mainUserLoadingHelper = new LoadingHelper();
  private _userLoadingHelper = new LoadingHelper();
  private _businessPersonLoadingHelper = new LoadingHelper();
  private _getGroupsLoadingHelper = new LoadingHelper();
  private _updateGroupsLoadingHelper = new LoadingHelper();

  private _componentSubscriptions = new Array<Subscription>();
  
  constructor(
    private _heroService: HeroService,
    private _userService: UserService,
    private _authenticationService: AuthenticationService,
    private _route: ActivatedRoute,
    private _dialogService: DialogService,
    private _modalService: NgbModal,    
    private _router: Router) {

    this._userId = this._route.snapshot.paramMap.get('userId');

    this.initHero();
  }

  ngOnInit() {
    this._componentSubscriptions.push(this._heroService.clickEvent.subscribe(_ => {
      this.onDeleteUser();
    }));

    this._componentSubscriptions.push(this._heroService.secondClickEvent.subscribe(_ => {
      this.onResendInvitationLink();
    }));

    this._componentSubscriptions.push(this._heroService.thirdClickEvent.subscribe(_ => {
      this._router.navigate([`../../`], { relativeTo: this._route });
    }));

    this.initForm();
    this.getUser();
  }
  
  ngOnDestroy(): void {
    this._componentSubscriptions.forEach(s => {
      s.unsubscribe();
    });
    this._componentSubscriptions.splice(0);
  }

  private initHero() {
    this.heroModel = new HeroModel();
    this.heroModel.title = "Användare";
    this.heroModel.subtitle = this.canAdministerUsers ? "Redigera" : "Visa";
    this._heroService.set(this.heroModel);    
    this._heroService.setThirdButton("Tillbaka");
  }
  
  get isMainLoadingUser() {
    return this._mainUserLoadingHelper.isLoading;
  }

  get isLoadingUser() {
    return this._userLoadingHelper.isLoading;
  }

  get isLoadingBusinessPerson() {
    return this._businessPersonLoadingHelper.isLoading;
  }

  get user() {
    return this._user;
  }

  get tenantMissingGroups(): boolean{
    return this._tenantMissingGroups;
  }

  get groupsInvalid(): boolean {
    return this.groupsFormArray.errors && this.groupsFormArray.dirty;
  }

  get groups() {
    return this._groups;
  }

  get submitDisabled(): boolean {
    return !this.formGroup.dirty;
  }

  get hasElevatedGroup(): boolean {
    var currentGroups = this.getSelectedGroups();
    return currentGroups.filter(g => g.elevated).length > 0;
  }

  get hasInternalGroups(): boolean{
    return this._groups.filter(g => g.internal).length > 0;
  }

  get businessSystemPerson(): BusinessSystemPerson {
    return this._user?.businessSystemPerson;
  }

  get frameAgreementId(): number {
    return this._user?.frameAgreement?.id;
  }

  public get canImpersonate(): boolean{
    return this._authenticationService.hasPermission(Permission.KörSomAnnan);
  }

  public get canAdministerAllUsers(): boolean{
    return this._authenticationService.hasPermission(Permission.AdministreraAllaAnvändare);
  }

  public get canAdministerUsers(): boolean{
    return this._authenticationService.hasAnyPermission([Permission.AdministreraAllaAnvändare, Permission.AdministreraFöretagsanvändare]);
  }

  public get isImpersonating(): boolean{
    return this._authenticationService.currentUserValue.isImpersonating;
  }

  public showFleetManagerWarning (groupIndex: number) {
    return this.canAdministerAllUsers && this._groups[groupIndex].isFleetManagerGroup && this.groupsFormArray.controls[groupIndex].value && !this._user.isFleetManagerInFleetSetup;
  }

  public showExternalGroupInfo(groupIndex: number){
    return !this.showRestrictedGroupInfo(groupIndex) && this._groups[groupIndex].external;
  }

  public showRestrictedGroupInfo(groupIndex: number){
    return this.canAdministerUsers && !this.canAdministerAllUsers && this._groups[groupIndex].elevated;
  }

  private initForm() {
    this.groupsFormArray = new FormArray<FormControl<boolean>>([], [CustomValidators.atLeastOneSelectedCheckbox]);
    this.formGroup = new FormGroup({
      groups: this.groupsFormArray
    });
  }

  onNewUserValue(request: UpdateFleetWebUserRequest) {
    this.updateUser(request);
  }

  onNewBusinessSystemPerson(user: BusinessSystemPerson) {    
    this.user.businessSystemPerson = user;
    this.refreshInvitationLinkButton();
  }

  onImpersonate(){
    if(this.isImpersonating){
      return;
    }

    this._userService.exists(this._user.userId).subscribe({
      next: result => {
        if(result){
          var modalRef = this._dialogService.showConfirm("Kör som...", `Vill du köra FleetWeb som användaren ${this._user.firstName} ${this._user.lastName}?<br/><br/>${this._user.userName}`, "Ja", "Avbryt");
          modalRef.result.then(result => {        
            if(result){
              this._authenticationService.impersonate(this._user.userId); 
            }                
          });
        }
        else{
          //Högst osannolikt att användaren skulle tagits bort i detta läge, men iaf.
          const modalRef = this._dialogService.showConfirm(
            "Användaren kunde inte hittas i Authway.",
            "Du kan välja att ta bort användaren även från FleetWeb. Vill du ta bort användaren?",
            "Ta bort",
            "Avbryt");
      
          modalRef.result.then(result => {
            if (result === true)
              this.deleteUser();
          });
        }
      },
      error: (error) => {
        this._dialogService.showError(error, "Kontrollera att användare finns", false);        
      },
      complete: () => {
        
      }
    });    
  }

  private onDeleteUser() {
    const modalRef = this._dialogService.showConfirm(
      "Ta bort användare",
      `Är du säker på att du vill ta bort användaren? ${ !this.canAdministerAllUsers && this.user.businessSystemPerson?.frameAgreement !== null ? "Tänk på att era förare kan behöva avsluta sin rapportering." : ""}` ,
      "Ta bort",
      "Avbryt");

    modalRef.result.then(result => {
      if (result === true)
        this.deleteUser();
    });
  }

  private getSelectedGroups(): Array<AuthwayUserGroup> {
    var groups = new Array<AuthwayUserGroup>();
    this.groupsFormArray.controls.forEach((control, index) => {
      if (control.value) {
        var group = this.groups[index];
        groups.push(group);
      }
    });
    return groups;
  }

  private getUser() {
    this._mainUserLoadingHelper.startLoading();
    this._user = null;

    this._userService.get(this._userId).subscribe({
      next: data => {
        this._user = data;
        this.refreshDeleteButton();
        this.refreshInvitationLinkButton();
        this.getAvailableUserGroups();
      },
      error: (error) => {
        this._mainUserLoadingHelper.stopLoading();        
        this._dialogService.showError(error, "Hämta användare", false);        
      },
      complete: () => {
        this._mainUserLoadingHelper.stopLoading();
      }
    });
  }

  onResendInvitationLink() {
    const modalRef = this._dialogService.showConfirm(
      "Skicka om inbjudan",
      "Är du säker på att du vill skicka om inbjudan till användaren?",
      "Skicka",
      "Avbryt");

    modalRef.result.then(result => {
      if (result === true)
        this.resendInvitationLink();
    });
  }

  resendInvitationLink() {
    var request = new FleetWebUserRequest();
    request.userId = this._user.userId;
    request.tenantId = this._user.organization.tenantId;
    
    this._userService.resendInvitationLink(request).subscribe({
      next: () => {
      },
      error: (error) => {
        this._userLoadingHelper.stopLoading();
        this._dialogService.showError(error, "Skicka om inbjudan", false);
      },
      complete: () => {
        this._userLoadingHelper.stopLoading();
      }
    });
  }

  private refreshDeleteButton() {    
    if (this.canAdministerUsers) {
      this._heroService.setButton("Ta bort");
  }
    else{
      this._heroService.setButton("");
    }
  }

  private refreshInvitationLinkButton() {    
    if (this.user.isConnected && this._user.canSendInvitationLink) {
      this._heroService.setSecondButton("Skicka om inbjudan");
    }
    else{
      this._heroService.setSecondButton("");
    }
  }

  private updateUser(request: UpdateFleetWebUserRequest) {
    this._userLoadingHelper.startLoading();
    
    this._userService.update(request).subscribe({
      next: (response) => {
        if(response instanceof ValidationErrorResponse){
          this._dialogService.showErrorMessage(response.error.title, response.error.detail);
          this.authwayUserEditorComponent.reset();
          return;
        }  
        
        this._user.firstName = request.firstName;
        this._user.lastName = request.lastName;
        this._user.userName = request.userName;

        this.authwayUserEditorComponent.update(this._user);

        if(request.userId === this._authenticationService.currentUserValue.sub){
          this._authenticationService.refreshCurrentUser();
        }
      },
      error: (error) => {
        this._userLoadingHelper.stopLoading();
        this.authwayUserEditorComponent.reset();
        this._dialogService.showError(error, "Spara användare", false);
      },
      complete: () => {
        this._userLoadingHelper.stopLoading();
      }
    });
  }

  private addGroup(formControl: FormControl<boolean>, groupName: string) {    
    if(this._updateGroupsLoadingHelper.isLoading){
      this.setValueWithoutEmitting(formControl, false);
      return;
    }

    this._updateGroupsLoadingHelper.startLoading();
    var request = new FleetWebUserGroupRequest();
    request.userId = this._userId;
    request.tenantId = this._user.organization.tenantId;
    request.group = groupName;

    this._userService.addGroup(request).subscribe({
      next: (validationProblem) => {
        if(validationProblem){
          this._dialogService.showErrorMessage("Lägg till behörighet till FleetWeb", validationProblem.error.detail);
          this.setValueWithoutEmitting(formControl, false);
        }
        else{
          this._user.groups.push(groupName);
          if(request.userId === this._authenticationService.currentUserValue.sub){
            this._authenticationService.refreshCurrentUser();
          }
        }
      },
      error: (error) => {        
        this._dialogService.showError(error, "Lägg till behörighet till FleetWeb", false);
        this.setValueWithoutEmitting(formControl, false);
        this._updateGroupsLoadingHelper.stopLoading();
      },
      complete: () => {
        this._updateGroupsLoadingHelper.stopLoading();
      }
    });
  }

  private removeGroup(formControl: FormControl<boolean>, groupName: string) {    
    if(this._updateGroupsLoadingHelper.isLoading){
      this.setValueWithoutEmitting(formControl, true);
      return;
    }

    this._updateGroupsLoadingHelper.startLoading();
    var request = new FleetWebUserGroupRequest();
    request.userId = this._userId;
    request.tenantId = this._user.organization.tenantId;
    request.group = groupName;
    
    this._userService.removeGroup(request).subscribe({
      next: (validationProblem) => {
        if(validationProblem){
          this._dialogService.showErrorMessage("Ta bort behörighet till FleetWeb", validationProblem.error.detail);
          this.setValueWithoutEmitting(formControl, true);
        }
        else{
          var groupIndex = this.user.groups.findIndex(g => g == groupName);
          this._user.groups.splice(groupIndex, 1);
          if(request.userId === this._authenticationService.currentUserValue.sub){
            this._authenticationService.refreshCurrentUser();
          }
        }
      },
      error: (error) => {        
        this._dialogService.showError(error, "Ta bort behörighet till FleetWeb", false);        
        this.setValueWithoutEmitting(formControl, true);
        this._updateGroupsLoadingHelper.stopLoading();
      },
      complete: () => {
        this._updateGroupsLoadingHelper.stopLoading();
      }
    });
  }

  private deleteUser() {
    this._mainUserLoadingHelper.startLoading();

    var request = new DeleteFleetWebUserRequest();
    request.userId = this._userId;

    this._userService.deleteUser(request).subscribe({
      next: () => {
        var modalRef = this._dialogService.showInfoMessage("Ta bort användare", "Användaren har tagits bort.");

        modalRef.result.then(() => {
          if(request.userId === this._authenticationService.currentUserValue.sub){
            this._authenticationService.handleUnAuthorizedUser();
          }
          else {
            this._router.navigate(["../../"], { relativeTo: this._route });
          }
        });
      },
      error: (error) => {
        this._mainUserLoadingHelper.stopLoading();
        this._dialogService.showError(error, "Ta bort användare", false);
      },
      complete: () => {
        this._mainUserLoadingHelper.stopLoading();
      }
    });
  }

  getAvailableUserGroups() {
    this._getGroupsLoadingHelper.startLoading();
    this.groupsFormArray.clear();
    this._groups = new Array<AuthwayUserGroup>();

    this._userService.getAvailableUserGroups(this._user.organization.tenantId).subscribe({
      next: (result) => {
        this._tenantMissingGroups = result.length === 0;

        this._groups = result.sort(this.groupSort);
        
        this._groups.forEach((group) => {
          var selectedGroupIndex = this._user.groups.findIndex(r => r === group.name);
          var selected = selectedGroupIndex !== -1;
          var control = new FormControl<boolean>(selected);

          if(!this.canAdministerUsers || group.external || (!this.canAdministerAllUsers && group.elevated)){
            control.disable({emitEvent: false});
          }
          this.groupsFormArray.push(control, {emitEvent: false});
        });
        
        this.subscribeToGroupChanges();
      },
      error: (error) => {
        this._getGroupsLoadingHelper.stopLoading();
        this._dialogService.showError(error, "Hämta grupper för användare", false);

      },
      complete: () => {
        this._getGroupsLoadingHelper.stopLoading();
      }
    });
  }

  private groupSort(a: AuthwayUserGroup, b: AuthwayUserGroup): number {
    if (a.internal != b.internal) {
      return a.internal ? 1 : -1;
    }
    else if (a.elevated != b.elevated) {
      return a.elevated ? 1 : -1;
    }

    return a.name.localeCompare(b.name);
  }

  private setValueWithoutEmitting(control: FormControl<boolean>, value: boolean){
    control.setValue(value, {emitEvent: false});   
  }

  private subscribeToGroupChanges(){    
    this._componentSubscriptions.push(merge(...this.groupsFormArray.controls.map((control: FormControl<boolean>, index: number) =>
      control.valueChanges.pipe(map(value => {        
        return { control: control, value: value, name: this._groups[index].name };
    }))))
    .subscribe(changes => {
      if(changes.value){
        this.addGroup(changes.control, changes.name);
      }
      else{        
        this.removeGroup(changes.control, changes.name);
      }
    }));
  }
}
