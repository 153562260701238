<div class="mb-4 c-card bg-grey" >
    <div class=" d-flex justify-content-between">
      <h5>Kopplad till person i affärssystemet</h5>                    
    </div>
    <div *ngIf="isEditing" class="mb-4">
        <form *ngIf="formGroup" [formGroup]="formGroup">
            <div class="o-fieldset__row mb-2">
                <label>Välj person</label>
                <app-business-system-person-search           
                    formControlName="person"
                    [clearSearchOnSelectedMatch]="true">
                </app-business-system-person-search>         
            </div>
        </form>       
    </div>
    <!-- <ng-template [ngIf]="!isValidating" [ngIfElse]="validating"> -->
      <div *ngIf="currentBusinessSystemPerson">
        <div class="mb-2">
          <span><b>Ramavtal: </b></span><span *ngIf="currentBusinessSystemPerson.frameAgreement !== null"> {{ currentBusinessSystemPerson.frameAgreement.name }} ({{currentBusinessSystemPerson.frameAgreement.quotaAccountName}}) </span>
        </div>
        <div *ngIf="currentBusinessSystemPerson.frameAgreement === null && !currentBusinessSystemPerson.multipleFrameAgreementCommitment" class="alert alert-info">
          Personen saknar engagemang i något ramavtal i affärssystemet.
        </div>
        <div *ngIf="currentBusinessSystemPerson.multipleFrameAgreementCommitment" class="alert alert-warning">
          Personens engagemang i affärssystemet spänner över fler än ett ramavtal.
        </div>
        <div *ngIf="currentBusinessSystemPerson.quotaAccountId !== null && user.frameAgreement?.quotaAccountId !== null && user.frameAgreement?.quotaAccountId !== currentBusinessSystemPerson.quotaAccountId" class="alert alert-warning">
          Det ramavtal som personen har sitt engagemang hos i affärssystemet skiljer sig mot det ramavtal som användarens organisation är kopplad mot.
        </div>
        <div>
          <span><b>Förnamn: </b>{{ currentBusinessSystemPerson.firstName }}</span>
        </div>
        <div>
          <span><b>Efternamn: </b>{{ currentBusinessSystemPerson.lastName }}</span>
        </div>
        <div>
          <span><b>Personnummer: </b>{{ currentBusinessSystemPerson.socialSecurityNumber }} </span>
        </div>
        <div>
          <span><b>Roller: </b>{{ currentBusinessSystemPerson.roles }} </span>
        </div>
        <div>
          <span><b>Kundnummer: </b>{{ currentBusinessSystemPerson.customerNumber }} </span>
        </div>
        <div>
          <span><b>Mobilnummer: </b>{{ currentBusinessSystemPerson.mobilePhone }} </span>
        </div>
        <div>
          <span><b>E-postadress: </b>{{ currentBusinessSystemPerson.email }} </span>
        </div>
        <div>
          <span><b>Anställningsnummer: </b>{{ currentBusinessSystemPerson.employeeId }} </span>
        </div>
      </div>
    <!-- </ng-template>
    <ng-template #validating>
      <div class="spinner-wrapper mb-4">
        <app-spinner [style]="'section'"></app-spinner>
      </div>
    </ng-template> -->
    <div *ngIf="!isEditing && !currentBusinessSystemPerson">
        <p>
            Användaren är inte kopplad till någon person i affärssystemet.
        </p>
    </div>
    <div  class="d-flex justify-content-end">
        <button *ngIf="!isEditing" type="button" class="c-btn c-btn--secondary" (click)="onEdit()" title="Ändra" [disabled]="isLoading" >
          <div *ngIf="isLoading" class="button-spinner-wrapper spinner-wrapper c-link__icon">
            <app-spinner [style]="'small'"></app-spinner>
          </div>
          <i *ngIf="!isLoading" class="c-link__icon c-icon c-icon--small c-icon--[pen]"></i> Ändra
        </button>
        <button *ngIf="isEditing" type="button" class="c-btn c-btn--secondary me-2" (click)="onCancel()" [disabled]="isLoading">
            <span class="c-btn__text">Avbryt</span>
        </button>
        <button *ngIf="isEditing && originalBusinessSystemPerson !== null" type="button" class="c-btn c-btn--secondary me-2" (click)="onDisconnect()" [disabled]="isLoading">
            <span class="c-btn__text">Koppla från</span>
        </button>
        <button *ngIf="isEditing" type="button" class="c-btn c-btn--primary" (click)="onConnect()" [disabled]="isLoading || !canConnect">
            <div *ngIf="isLoading" class="button-spinner-wrapper spinner-wrapper c-link__icon">
              <app-spinner [style]="'small'"></app-spinner>
            </div>
            <ng-container *ngIf="originalBusinessSystemPerson === null">
              <span *ngIf="!canSendInvitation" class="c-btn__text">Koppla</span>
              <span *ngIf="canSendInvitation" class="c-btn__text">Koppla och skicka inbjudan</span>
            </ng-container>
            <span *ngIf="originalBusinessSystemPerson !== null" class="c-btn__text">Koppla om</span>
        </button>
    </div>
  </div>
