<ng-template [ngIf]="user" [ngIfElse]="loading">
  <form [formGroup]="formGroup">
    <div class="o-fieldset__row mt-4 mb-4 c-card">
      <div class="d-flex justify-content-between">
        <div>
          <h5 *ngIf="user.firstName || user.lastName">{{ user.firstName }} {{user.lastName}} <span *ngIf="user.socialSecurityNumber">({{user.socialSecurityNumber}})</span></h5>
        </div>
        <div>
          <div class="d-flex justify-content-end">
            <small><b>Skapad: </b>{{ user ? (user.createdAtUtc | date: 'yyyy-MM-dd HH:mm:ss') : '-'}}</small>
          </div>
          <div class="d-flex justify-content-end">
            <small><b>Senast inloggad: </b>{{ user ? (user.lastLoginDate | date: 'yyyy-MM-dd HH:mm:ss') : '-'}}</small>
          </div>
        </div>
      </div>
      <div *ngIf="user.frameAgreement">
        <span><b>Ramavtal: </b>{{ user.frameAgreement.name }} ({{ user.frameAgreement.quotaAccountName }})</span>
      </div>
      <div *ngIf="user.organization">
        <span><b>Organisation: </b>{{ user.organization.name }} ({{ user.organization.organizationNumber }})</span>
      </div>
      <div *ngIf="user.hasConflictingFrameAgreements" class="alert alert-warning mt-4">
        Ramavtalet som organisationen är kopplad mot skiljer sig mot det ramavtal som användaren har sitt engagemang hos i affärssystemet.
      </div>
      <div class="o-fieldset__row mt-4 mb-2">
        <label for="newUserInput">Användarnamn (e-postadress)*</label>
        <div class="c-input mb-1">
          <input id="newUserInput" formControlName="userName" class="c-input__input" type="text" maxlength="255" [class.is-error]="emailInvalid" />
        </div>
        <small *ngIf="emailRequiredInvalid" class="is-error">
          Användarnamn måste anges.
        </small>
        <small *ngIf="emailPatternInvalid" class="is-error">
          Användarnamn är inte en giltig e-postadress.
        </small>
      </div>
      <div *ngIf="emailDifferentFromView21" class="alert alert-warning">
        Användarnamnet skiljer sig från e-postadressen i affärssystemet.
      </div>
      <div class="o-fieldset__row mb-2">
        <label for="firstNameInput">Förnamn*</label>
        <div class="c-input mb-1">
          <input id="firstNameInput" formControlName="firstName" class="c-input__input" type="text" maxlength="255" [class.is-error]="firstNameInvalid" />
        </div>
        <small *ngIf="firstNameInvalid" class="is-error">
          Förnamn måste anges.
        </small>
      </div>
      <div *ngIf="firstNameDifferentFromView21" class="alert alert-warning">
        Förnamnet skiljer sig från förnamnet i affärssystemet.
      </div>
      <div class="o-fieldset__row mb-2">
        <label for="lastNameInput">Efternamn*</label>
        <div class="c-input mb-1">
          <input id="lastNameInput" formControlName="lastName" class="c-input__input" type="text" maxlength="255" [class.is-error]="lastNameInvalid" />
        </div>
        <small *ngIf="lastNameInvalid" class="is-error">
          Efternamn måste anges.
        </small>
      </div>
      <div *ngIf="lastNameDifferentFromView21" class="alert alert-warning">
        Efternamnet skiljer sig från efternamnet i affärssystemet.
      </div>
      <ng-container *ngIf="canAdministerUsers">
        <div *ngIf="!isEditing" class="d-flex justify-content-end mt-4">
          <button type="button" class="c-btn c-btn--secondary" (click)="onEdit()" title="Ändra">
            <i class="c-link__icon c-icon c-icon--small c-icon--[pen]"></i> Ändra
          </button>
        </div>
        <div *ngIf="isEditing" class="d-flex justify-content-end mt-4">
          <button type="button" class="c-btn c-btn--secondary me-2" (click)="onCancel()">
            <span class="c-btn__text">Avbryt</span>
          </button>
          <button type="button" class="c-btn c-btn--primary" (click)="onSave()">
            <span class="c-btn__text">Spara</span>
          </button>
        </div>
      </ng-container>
    </div>
  </form>
</ng-template>
<ng-template #loading>
  <div class="c-card spinner-wrapper">
    <app-spinner [style]="'section'"></app-spinner>
  </div>
</ng-template>
