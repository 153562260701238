import { Injectable } from "@angular/core";
import { CarAndDriverQuery } from "@domain/models/filters/car-and-driver-query-model";
import { SearchExternalReportQuery } from "@domain/models/filters/search-external-reports-query.model";
import { SearchFleetWebUserQuery } from "@domain/models/filters/search-fleetweb-user-query.model";
import { SearchSuborderQuery } from "@domain/models/filters/search-suborder-query.model";
import { SessionStorageService } from "./session-storage.service";

@Injectable()
export class LocalStorageService {

    private static carAndDriverQueryKey = "companyCarAndDriverQuery";
    private static searchSuborderQueryKey = "searchSuborderQuery";
    private static searchExternalMileageReportQueryKey = "searchExternalMileageReportQuery";
    private static searchExternalTollReportQueryKey = "searchExternalTollReportQuery";
    private static searchCompanyFleetWebUserQueryKey = "searchCompanyFleetWebUserQuery";
    private static searchFleetWebUserQueryKey = "searchFleetWebUserQuery";
    private static returnUrlKey = "fleetWebReturnUrl";

    /**
     *
     */
    constructor(private _sessionStorage: SessionStorageService ) {
                
    }

    //We need to be able to store the search filters for a user that impersonates another user and reverts back
    getUserKey(key : string){
        if(this._sessionStorage.currentUser == null){
            throw new Error("User not set");
        }

        return `${key}_${this._sessionStorage.currentUser.sub}_${this._sessionStorage.currentUser.impersonatorUserName ?? ''}`;
    }
    
    get returnUrl() : string {                
        const returnUrl = localStorage.getItem(LocalStorageService.returnUrlKey) ?? '/'; 
        // Once the return url has been used, it is removed
        localStorage.removeItem(LocalStorageService.returnUrlKey);
        return returnUrl;
    }
    set returnUrl(value: string) {
        if(value === null){
            localStorage.removeItem(LocalStorageService.returnUrlKey);
            return;
        }

        localStorage.setItem(LocalStorageService.returnUrlKey, value);
    }
    
    get searchSuborderQuery() : SearchSuborderQuery {
        const queryAsJson = localStorage.getItem(this.getUserKey(LocalStorageService.searchSuborderQueryKey));
        return (queryAsJson !== null && queryAsJson !== "") ?
            new SearchSuborderQuery(JSON.parse(queryAsJson)) : 
            null;
    }
    set searchSuborderQuery(value: SearchSuborderQuery) {
        const queryAsJson = JSON.stringify(value);
        localStorage.setItem(this.getUserKey(LocalStorageService.searchSuborderQueryKey), queryAsJson);
    }

    get carAndDriverQuery() : CarAndDriverQuery {
        const queryAsJson = localStorage.getItem(this.getUserKey(LocalStorageService.carAndDriverQueryKey));
        return (queryAsJson !== null && queryAsJson !== "") ?
          new CarAndDriverQuery(JSON.parse(queryAsJson)) : 
          null;               
    }
    set carAndDriverQuery(value: CarAndDriverQuery) {
        const queryAsJson = JSON.stringify(value);
        localStorage.setItem(this.getUserKey(LocalStorageService.carAndDriverQueryKey), queryAsJson);
    }

    get searchExternalMileageReportQuery() : SearchExternalReportQuery{
        const queryAsJson = localStorage.getItem(this.getUserKey(LocalStorageService.searchExternalMileageReportQueryKey));
        return (queryAsJson !== null && queryAsJson !== "") ?
          new SearchExternalReportQuery(JSON.parse(queryAsJson)) : 
          null; 
    }
    set searchExternalMileageReportQuery(value : SearchExternalReportQuery){
        const queryAsJson = JSON.stringify(value);
        localStorage.setItem(this.getUserKey(LocalStorageService.searchExternalMileageReportQueryKey), queryAsJson);
    }

    get searchCompanyFleetWebUserQuery() : SearchFleetWebUserQuery{
        const queryAsJson = localStorage.getItem(this.getUserKey(LocalStorageService.searchCompanyFleetWebUserQueryKey));
        return (queryAsJson !== null && queryAsJson !== "") ?
          new SearchFleetWebUserQuery(JSON.parse(queryAsJson)) : 
          null; 
    }
    set searchCompanyFleetWebUserQuery(value : SearchFleetWebUserQuery){
        const queryAsJson = JSON.stringify(value);
        localStorage.setItem(this.getUserKey(LocalStorageService.searchCompanyFleetWebUserQueryKey), queryAsJson);
    }

    get searchFleetWebUserQuery() : SearchFleetWebUserQuery{
        const queryAsJson = localStorage.getItem(this.getUserKey(LocalStorageService.searchFleetWebUserQueryKey));
        return (queryAsJson !== null && queryAsJson !== "") ?
          new SearchFleetWebUserQuery(JSON.parse(queryAsJson)) : 
          null; 
    }
    set searchFleetWebUserQuery(value : SearchFleetWebUserQuery){
        const queryAsJson = JSON.stringify(value);
        localStorage.setItem(this.getUserKey(LocalStorageService.searchFleetWebUserQueryKey), queryAsJson);
    }

    get searchExternalTollReportQuery() : SearchExternalReportQuery{
        const queryAsJson = localStorage.getItem(this.getUserKey(LocalStorageService.searchExternalTollReportQueryKey));
        return (queryAsJson !== null && queryAsJson !== "") ?
          new SearchExternalReportQuery(JSON.parse(queryAsJson)) : 
          null; 
    }
    set searchExternalTollReportQuery(value : SearchExternalReportQuery){
        const queryAsJson = JSON.stringify(value);
        localStorage.setItem(this.getUserKey(LocalStorageService.searchExternalTollReportQueryKey), queryAsJson);
    }

    clearSearchSuborderQuery() {
        localStorage.removeItem(this.getUserKey(LocalStorageService.searchSuborderQueryKey));
    }

    clearCarAndDriverQuery() {
        localStorage.removeItem(this.getUserKey(LocalStorageService.carAndDriverQueryKey));
    }
}
