<div class="c-hero-teaser" [class.bg-cover]="!content.bgSize" [class.bg-cover-small]="content.bgSize === 'small'"
     [class.bg-cover-medium]="content.bgSize === 'medium'">
  <img src="{{content.imageUrl ? content.imageUrl : defaultImage}}" class="c-hero-teaser__image">
</div>
<div class="c-hero-teaser__content hero-title">
  <div class="o-page-wrap" [class.page-margin]="hasInternalUserPermissions">
    <header class="c-hero-teaser__heading">
      <div class="d-flex justify-content-between">
        <div class="w-100">
          <div *ngIf="content.pretitle" class="c-hero-teaser__pretitle ">
            <p>{{content.pretitle}}</p>
          </div>
          <div *ngIf="content.title" class="c-hero-teaser__title">
            <h1><span class="c-hero-teaser__title-row">{{content.title}}</span></h1>
          </div>
          <div *ngIf="content.subtitle" class="c-hero-teaser__subtitle ">
            <p>{{content.subtitle}}</p>
          </div>
          <div class="row mb-1" >
            <div *ngIf="content.buttonText" class="col-auto c-hero-teaser__button">
              <a class="c-btn" (click)="buttonClicked()">
                <span class="c-btn__text">{{content.buttonText}}</span>
              </a>
            </div>
            <div *ngIf="content.secondButtonText" class="col-auto c-hero-teaser__button" [class.ps-0]="content.buttonText">
              <a class="c-btn" (click)="secondButtonClicked()">
                <span class="c-btn__text">{{content.secondButtonText}}</span>
              </a>
            </div>
            <div *ngIf="content.thirdButtonText" class="col-auto c-hero-teaser__button" [class.ps-0]="content.buttonText || content.secondButtonText">
              <a class="c-btn c-btn--secondary" (click)="thirdButtonClicked()">
                <span class="c-btn__text">{{content.thirdButtonText}}</span>
              </a>
            </div>
          </div>
        </div>
        <div *ngIf="showImpersonatingInformation" class="w-50 me-2">
          <div class="alert alert-super-user"> 
            <div class="d-flex justify-content-start">
              <i class="c-icon--small fa c-icon c-icon--[user]"></i>
              <ul>
                <li class="mb-1" *ngFor="let problem of user.validationProblems">
                  <span><small class="m-0">{{ problem.message }}</small></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div *ngIf="showFleetManagerWarning" class="w-50">
          <div class="alert alert-danger"> 
            <div class="d-flex justify-content-start">
              <i class="c-icon--small fa c-icon c-icon--[attention]"></i>
              <ul>
                <li class="mb-1" >
                  <span><small class="m-0">Du har behörigheten FleetWeb FleetManager, men din firmatecknare behöver signera ett tillägg till ramavtalet för att du skall kunna signera avrop.</small></span>                
                </li>
              </ul>
            </div>
        </div>
        </div>        
      </div>
    </header>
  </div>
</div>
