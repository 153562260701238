import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Permission } from '@core/models/permissions.enum';
import { AuthenticationService } from '@core/services/authentication.service';
import { FleetWebUserDetails } from '@domain/models/user/fleetweb-user-details.model';
import { UpdateFleetWebUserRequest } from '@domain/models/user/update-fleetweb-user-request.model';

@Component({
  selector: 'app-authway-user-editor',
  templateUrl: './authway-user-editor.component.html',
  styleUrls: ['./authway-user-editor.component.scss']
})
export class AuthwayUserEditorComponent implements OnInit {

  @Input() user: FleetWebUserDetails = null;
  @Output() newValue: EventEmitter<UpdateFleetWebUserRequest> = new EventEmitter();

  formGroup: FormGroup;
  userNameFormControl: FormControl<string>;
  firstNameFormControl: FormControl<string>;
  lastNameFormControl: FormControl<string>;

  constructor(private _authenticationService: AuthenticationService) {
        
  }

  ngOnInit() {
    this.initForm();
  }

  get isEditing() {
    return !this.formGroup.disabled;
  }

  get firstNameInvalid(): boolean {
    return this.firstNameFormControl.errors && this.firstNameFormControl.dirty;
  }

  get lastNameInvalid(): boolean {
    return this.lastNameFormControl.errors && this.lastNameFormControl.dirty;
  }

  get emailInvalid(): boolean {
    return this.emailRequiredInvalid || this.emailPatternInvalid;
  }

  get emailRequiredInvalid(): boolean {
    return this.userNameFormControl.errors && this.userNameFormControl.errors.required && this.userNameFormControl.dirty;
  }

  get emailPatternInvalid(): boolean {
    return this.userNameFormControl.errors && this.userNameFormControl.errors.email && this.userNameFormControl.dirty;
  }

  get emailDifferentFromView21(): boolean {
    if(!this.canAdministerAllUsers){
      return false;
    }
    var userName = this.userNameFormControl.value;
    return userName != null && userName != "" && this.user.businessSystemPerson && this.user.businessSystemPerson.email != userName;
  }
  
  get firstNameDifferentFromView21(): boolean {
    if(!this.canAdministerAllUsers){
      return false;
    }
    var firstName = this.firstNameFormControl.value;
    return firstName != null && firstName != "" && this.user.businessSystemPerson && this.user.businessSystemPerson.firstName != firstName;
  }
  
  get lastNameDifferentFromView21(): boolean {
    if(!this.canAdministerAllUsers){
      return false;
    }
    var lastName = this.lastNameFormControl.value;
    return lastName != null && lastName != "" && this.user.businessSystemPerson && this.user.businessSystemPerson.lastName != lastName;
  }

  private initForm() {
    this.userNameFormControl = new FormControl<string>(this.user.userName, { validators: [Validators.required, Validators.email], updateOn: 'blur' });
    this.firstNameFormControl = new FormControl<string>(this.user.firstName, { validators: [Validators.required], updateOn: 'blur' });
    this.lastNameFormControl = new FormControl<string>(this.user.lastName, { validators: [Validators.required], updateOn: 'blur' });

    this.formGroup = new FormGroup(
      {
        firstName: this.firstNameFormControl,
        lastName: this.lastNameFormControl,
        userName: this.userNameFormControl
      });

    this.formGroup.disable();
  }

  get canAdministerAllUsers(): boolean{
    return this._authenticationService.hasPermission(Permission.AdministreraAllaAnvändare);
  }

  public get canAdministerUsers(): boolean{
    return this._authenticationService.hasAnyPermission([Permission.AdministreraAllaAnvändare, Permission.AdministreraFöretagsanvändare]);
  }

  onEdit() {
    this.formGroup.enable();
  }

  onCancel() {
    this.reset();
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.userNameFormControl.markAsDirty();
      this.firstNameFormControl.markAsDirty();
      this.lastNameFormControl.markAsDirty();
      return;
    }

    var updateUserRequest = new UpdateFleetWebUserRequest();

    updateUserRequest.userId = this.user.userId;
    updateUserRequest.tenantId = this.user.organization.tenantId;
    updateUserRequest.userName = this.userNameFormControl.value;
    updateUserRequest.firstName = this.firstNameFormControl.value;
    updateUserRequest.lastName = this.lastNameFormControl.value;

    this.newValue.emit(updateUserRequest);
  }

  reset() {
    this.formGroup.reset();
    this.formGroup.disable();
    this.firstNameFormControl.setValue(this.user.firstName);
    this.lastNameFormControl.setValue(this.user.lastName);
    this.userNameFormControl.setValue(this.user.userName);
  }

  update(user: FleetWebUserDetails) {
    this.formGroup.reset();
    this.formGroup.disable();
    this.firstNameFormControl.setValue(user.firstName);
    this.lastNameFormControl.setValue(user.lastName);
    this.userNameFormControl.setValue(user.userName);
  }
  
}
