<section class="o-page-wrap u-mt mb-5">
  <div class="report-filter mt-5 mb-5 pt-5 pb-5 bg-grey">
    <div class="o-page-wrap o-page-wrap--xsmall">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" class="w-100">
        <div class="row">
          <div class="col">
            <fieldset class="o-fieldset u-mb">
              <div *ngIf="canAdministrateAllUsers" class="o-fieldset__row mt-4 mb-4">
                <label class="c-radio">
                    <input class="c-radio__input js-pf-radio-button" type="radio" formControlName="searchMode" [value]="SearchMode.Existing">
                    <span class="c-radio__label">Sök befintliga användare</span>
                </label>
                <label class="c-radio ms-3">
                    <input class="c-radio__input js-pf-radio-button" type="radio" formControlName="searchMode" [value]="SearchMode.Potential">
                    <span class="c-radio__label">Sök personer i affärssystemet</span>
                </label>
              </div>
              <div *ngIf="canAdministrateAllUsers" class="o-fieldset__row mb-2">
                <label for="frameAgreement">Ramavtal</label>
                <app-frame-agreement-search-advanced id="organization" formControlName="frameAgreement" [includeDisabled]="includeDisabled"></app-frame-agreement-search-advanced>              
              </div>
              <div *ngIf="showSuperUserDisclaimer" class="alert alert-warning">
                <p>När du söker efter personer i affärssystemet på ett visst ramavtal, kommer du endast kunna få sökträffar för personer som är förare eller Fleet Manager på ett kontrakt. </p>
                <p>För att lägga upp nya användare utan engagemang, sök utan angivet ramavtal.</p>                                
              </div>              
              <div *ngIf="searchMode === SearchMode.Existing" class="mt-4 mb-4">
                <ng-template [ngIf]="!isGroupsLoading" [ngIfElse]="groupsLoading">
                  <label for="groups">Behörighet till FleetWeb</label>
                  <div id="groups" formArrayName="groups" *ngFor="let check of groupsFormArray.controls; let i = index" class="switch">
                    <input type="checkbox" class="switch" [id]="groups[i]" [formControl]="check" />
                    <label [for]="groups[i]">{{ groups[i] }}</label>
                  </div>                  
                </ng-template>              
                <ng-template #groupsLoading>
                  <div class="c-card spinner-wrapper">
                    <app-spinner [style]="'section'"></app-spinner>
                  </div>
                </ng-template>
              </div>              
              <div class="o-fieldset__row mb-2">
                <label for="firstName">Förnamn</label>
                <div class="c-input mb-3">
                  <input id="firstName" formControlName="firstName" class="c-input__input">
                </div>
              </div>
              <div class="o-fieldset__row mb-2">
                <label for="lastname">Efternamn</label>
                <div class="c-input mb-3">
                  <input id="lastname" formControlName="lastName" class="c-input__input">
                </div>
              </div>
              <div class="o-fieldset__row mb-2">
                <label for="socialSecurityNumber">Personnummer</label>
                <div class="c-input mb-3">
                  <input id="socialSecurityNumber" formControlName="socialSecurityNumber" class="c-input__input">
                </div>
              </div>
              <div class="o-fieldset__row mb-2">
                <label for="email">E-post</label>
                <div class="c-input mb-3">
                  <input id="email" formControlName="email" class="c-input__input">
                </div>
              </div>             
            </fieldset>
          </div>
        </div>
        <div class="d-flex mt-auto justify-content-between">
          <button type="button" class="c-btn c-btn--secondary c-btn--full me-2" (click)="onReset()">
            <span class="c-btn__text">Rensa</span>
          </button>
          <button type="submit" class="c-btn c-btn--primary c-btn--full ms-2" [disabled]="isLoading">
            <span class="c-btn__text">Sök</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</section>
<ng-template [ngIf]="!isLoading" [ngIfElse]="searchLoading">
  
  <section *ngIf="users && users.length" class="o-page-wrap u-mt mb-5">
    <div *ngIf="canExport" class="d-flex justify-content-end">
      <button class="c-btn c-btn--secondary" (click)="onExportToExcel()">
        <i class="c-link__icon  c-icon c-icon--[document-xls] c-icon--small"></i>
        <span class="c-link__body">
          <span class="c-link__effect">Exportera</span>          
        </span>
      </button>
    </div>
    <app-pagination [settings]="paginationSettings" (pageChanged)="onPageChanged($event)" (pageSizeChanged)="onPageSizeChanged($event)"></app-pagination>
  </section>
  <section *ngIf="users && users.length" class="o-page-wrap u-mt mb-5 min-width-1200 p-0">
    <div class="report-table">
      <table cellspacing="0">
        <tr class="text-start text-primary">
          <th *ngIf="canImpersonate" ></th>
          <th class="">Förnamn</th>
          <th class="">Efternamn</th>
          <th class="">Personnummer</th>
          <th class="">E-post</th>
          <th class="">Behörighet till FleetWeb</th>
          <th *ngIf="canAdministrateAllUsers" class="">Ramavtal/organisation</th>
          <th class="">Senast inloggad</th>
          <th class=""></th>
        </tr>
        <tr *ngFor="let user of users">
          <td *ngIf="canImpersonate" >          
            <button *ngIf="user.userId !== null" [disabled]="isImpersonating" type="button" title="Kör som..." class="c-btn c-btn--link" (click)="onImpersonate(user)">
              <i  class="fa c-icon c-icon--[user] c-icon--small"></i>          
            </button>
          </td>
          <td>{{user.firstName}}</td>
          <td>{{user.lastName}}</td>
          <td>{{user.socialSecurityNumber}}</td>
          <td>{{user.userName}}</td>
          <td>
            <ul class="list-group list-group-flush">
              <li class="list-group-item" *ngFor="let group of user.groups">{{group}}</li>
            </ul>
          </td>
          <td *ngIf="canAdministrateAllUsers">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">{{user.frameAgreement?.name ?? '-' }}</li>
              <li class="list-group-item">{{ user.organization?.name ?? '-' }}</li>
            </ul>
            
          </td>
          <td><span *ngIf="user.lastLoginDate !== null"> {{user.lastLoginDate | date: 'yyyy-MM-dd HH:mm:ss'}}</span></td>
          <td >    
            <div class="justify-content-end align-items-center d-flex">
              <button *ngIf="getUserAction(user) !== UserAction.None" type="button" [title]="getUserActionText(user)" [disabled]="getUserAction(user) === UserAction.CreatePrevented" class="c-btn c-btn--link" (click)="onUserAction(user)">
                <span class="c-btn__text"> {{ getUserActionText(user) }}</span>
              </button>    
              <button *ngIf="getUserAction(user) === UserAction.CreatePrevented" type="button" title="Kan inte skapa användare" class="btn c-btn--secondary btn-circle ms-2" [ngbPopover]="getCreatePreventedReason(user)" popoverTitle="Kan inte skapa användare">i</button>
            </div>      
          </td>
        </tr>
      </table>
    </div>
  </section>
  <section *ngIf="users && users.length === 0 && !isLoading" class="o-page-wrap u-mt mb-5">
    <div class="u-text-center">
      Din sökning gav inte något resultat.
    </div>
  </section>

</ng-template>
<ng-template #searchLoading>
  <div class="spinner-wrapper">
    <app-spinner [style]="'section'"></app-spinner>
  </div>
</ng-template>
