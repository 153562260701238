import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';

// External libraries
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DigitOnlyModule } from '@uiowa/digit-only';
import { NgxFilesizeModule } from 'ngx-filesize';

import { CarBasicCardComponent } from './components/car-basic-card/car-basic-card.component';
import { CarDetailCardComponent } from './components/car-detail-card/car-detail-card.component';
import { DriverDetailCardComponent } from './components/driver-detail-card/driver-detail-card.component';
import { ReportComponent } from './components/report/report.component';
import { ReportStepContentComponent } from './components/report/report-step-content/report-step-content.component';
import { DynamicContainerDirective } from './directives/dynamic-container.directive';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { CarBasicListCardComponent } from './components/car-basic-list-card/car-basic-list-card.component';
import { KilometerPipe } from '@shared/pipes/kilometer.pipe';
import { ErrorCardComponent } from './components/error-card/error-card.component';
import { CommonInfoMessageComponent } from './components/modals/common-info-message/common-info-message.component';
import { FilterPipe } from './pipes/filter.pipe';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { WizardComponent } from './components/wizard/wizard.component';
import { WizardStepContentComponent } from './components/wizard/wizard-step-content/wizard-step-content.component';
import { SimpleFilterComponent } from './components/simple-filter/simple-filter.component';
import { ConfirmDialogComponent } from './components/modals/confirm-dialog/confirm-dialog.component';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { UploadFileComponent } from './components/modals/upload-file/upload-file.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { DriverSearchComponent } from "@shared/components/driver-search/driver-search.component";
import { SellerSearchComponent } from "@shared/components/seller-search/seller-search.component";
import { CarSearchComponent } from "@shared/components/car-search/car-search.component";
import { NewsCardComponent } from './components/news-card/news-card.component';
import { SafeHtmlPipe } from "@shared/pipes/safe-html-pipe";
import { CarInfoModalComponent } from '@shared/components/modals/car-info-modal/car-info-modal.component';
import { DriverInfoModalComponent } from './components/modals/driver-info-modal/driver-info-modal.component';
import { ExternalReportStatusPipe } from './pipes/external-report-status.pipe';
import { ExternalMileageReportTypePipe } from './pipes/external-mileage-report-type.pipe';
import { ReportsValidationMessageComponent } from './components/modals/reports-validation-message/reports-validation-message.component';
import { CustomerSearchComponent } from '@shared/components/customer-search/customer-search.component';
import { DealerSearchComponent } from '@shared/components/dealer-search/dealer-search.component';
import { NotificationGroupCardComponent } from './components/notification-group-card/notification-group-card.component';
import { ContentTypeIconPipe } from './pipes/contenttypeicon.pipe';
import { FrameAgreementSearchComponent } from '@shared/components/frame-agreement-search/frame-agreement-search.component';
import { EditUserOrganizationCostcentersComponent } from './components/user-admin/edit-user-organization-costcenters/edit-user-organization-costcenters.component';
import { FrameAgreementSelectorComponent } from './components/user-admin/frame-agreement-selector/frame-agreement-selector.component';
import { CustomersSelectorComponent } from './components/user-admin/customers-selector/customers-selector.component';
import { ForceLogoutComponent } from './components/user-admin/force-logout/force-logut.component';
import { CustomerFilterComponent } from "@shared/components//customer-filter/customer-filter.component";
import { BusinessSystemPersonSearchComponent } from './components/business-system-person-search/business-system-person-search.component';
import { OrganizationSearchComponent } from './components/organization-search/organization-search.component';
import { InputEditorComponent } from './components/user-admin/input-editor/input-editor.component';
import { FrameAgreementSearchAdvancedComponent } from './components/frame-agreement-search-advanced/frame-agreement-search-advanced.component';
import { AuthwayUserEditorComponent } from './components/user-admin/authway-user-editor/authway-user-editor.component';
import { CreateUserComponent } from './components/user-admin/create-user/create-user.component';
import { BusinessSystemPersonInfoComponent } from './components/user-admin/business-system-person-info/business-system-person-info.component';
import { EditUserComponent } from './components/user-admin/edit/edit-user.component';
import { UserSearchComponent } from './components/user-admin/search/user-search.component';
import { ExportUsersComponent } from './components/user-admin/export-users/export-users.component';
import { SignatorySignatureRequestComponent } from './components/user-admin/signatory-signature-request/signatory-signature-request.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,

    //External modules
    DigitOnlyModule,
    NgbModule,
    NgxFilesizeModule,
    ReactiveFormsModule,

    // Standalone Components
    SpinnerComponent,
    ConfirmDialogComponent,
    CommonInfoMessageComponent,
    CarInfoModalComponent,
    CarBasicCardComponent,
    CarBasicListCardComponent,
    CarDetailCardComponent,
    KilometerPipe,
    CarSearchComponent,
    CustomerSearchComponent,
    DealerSearchComponent,
    DriverSearchComponent,
    FrameAgreementSearchComponent,
    CustomerFilterComponent,
    SellerSearchComponent,
    BusinessSystemPersonSearchComponent,
    OrganizationSearchComponent
  ],
  declarations: [
    DriverDetailCardComponent,
    NotificationGroupCardComponent,
    NewsCardComponent,
    ReportComponent,
    ReportStepContentComponent,
    DynamicContainerDirective,
    ReportsValidationMessageComponent,
    PaginationComponent,
    ErrorCardComponent,
    FilterPipe,
    ThankYouComponent,
    WizardComponent,
    WizardStepContentComponent,
    SimpleFilterComponent,
    DragAndDropDirective,
    UploadFileComponent,
    ClickOutsideDirective,
    SafeHtmlPipe,
    DriverInfoModalComponent,
    ExternalReportStatusPipe,
    ExternalMileageReportTypePipe,
    ContentTypeIconPipe,
    UserSearchComponent,
    EditUserComponent,
    EditUserOrganizationCostcentersComponent,
    FrameAgreementSelectorComponent,
    FrameAgreementSearchAdvancedComponent,
    CustomersSelectorComponent,
    ForceLogoutComponent,
    CreateUserComponent,
    InputEditorComponent,
    AuthwayUserEditorComponent,
    BusinessSystemPersonInfoComponent,
    ExportUsersComponent,
    SignatorySignatureRequestComponent
  ],
  exports: [
    DigitOnlyModule,
    NgbModule,
    NgxFilesizeModule,
    DriverDetailCardComponent,
    NotificationGroupCardComponent,
    NewsCardComponent,
    ReportComponent,
    ReportStepContentComponent,
    DynamicContainerDirective,
    ReportsValidationMessageComponent,
    PaginationComponent,
    ErrorCardComponent,
    FilterPipe,
    ThankYouComponent,
    SimpleFilterComponent,
    DragAndDropDirective,
    UploadFileComponent,
    ClickOutsideDirective,
    SafeHtmlPipe,
    ExternalReportStatusPipe,
    ExternalMileageReportTypePipe,
    ContentTypeIconPipe,
    UserSearchComponent,
    EditUserComponent,
    ForceLogoutComponent,
    ExportUsersComponent,
    CreateUserComponent,
    SpinnerComponent,
    ConfirmDialogComponent,
    CommonInfoMessageComponent,
    CarInfoModalComponent,
    CarBasicCardComponent,
    CarBasicListCardComponent,
    CarDetailCardComponent,
    KilometerPipe,
    CarSearchComponent,
    CustomerSearchComponent,
    DealerSearchComponent,
    DriverSearchComponent,
    FrameAgreementSearchComponent,
    CustomerFilterComponent,
    SellerSearchComponent,
    BusinessSystemPersonSearchComponent,
    FrameAgreementSelectorComponent,
    FrameAgreementSearchAdvancedComponent,
    OrganizationSearchComponent,
    InputEditorComponent,
    AuthwayUserEditorComponent,
    BusinessSystemPersonInfoComponent
  ]
})
export class SharedModule { }
