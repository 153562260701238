import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingHelper } from '@shared/helpers/loading.helper';
import { Subscription } from 'rxjs';
import { UserService } from '@domain/services/user.service';
import { FleetWebUserDetails } from '@domain/models/user/fleetweb-user-details.model';
import { ApplicationError } from '@core/models/application-error.model';
import { SignatorySignatureRequest } from '@domain/models/user/signatory-signature-request.model';

@Component({
  selector: 'app-signatory-signature-request',
  templateUrl: './signatory-signature-request.component.html',
  styleUrls: ['./signatory-signature-request.component.scss']
})
export class SignatorySignatureRequestComponent implements OnDestroy, OnInit {

  @Input() userDetails: FleetWebUserDetails = null;
  @Input() group: string;
  
  private _componentSubscriptions = new Array<Subscription>();

  formGroup: FormGroup;
  private _emailFormControl: FormControl<string>;
  private _firstNameFormControl: FormControl<string>;
  private _lastNameFormControl: FormControl<string>;

  private _loadingHelper = new LoadingHelper();
  
  private _errorMessage = "";

  constructor(
    private _userService: UserService,
    public modal: NgbActiveModal) { }


  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this._componentSubscriptions.forEach(s => {
      s.unsubscribe();
    });
    this._componentSubscriptions.splice(0);
  }

  get firstNameInvalid(): boolean {
    return this._firstNameFormControl.errors && this._firstNameFormControl.dirty;
  }

  get lastNameInvalid(): boolean {
    return this._lastNameFormControl.errors && this._lastNameFormControl.dirty;
  }

  get emailInvalid(): boolean {
    return this.emailRequiredInvalid || this.emailPatternInvalid;
  }

  get emailRequiredInvalid(): boolean {
    return this._emailFormControl.errors && this._emailFormControl.errors.required && this._emailFormControl.dirty;
  }

  get emailPatternInvalid(): boolean {
    return this._emailFormControl.errors && this._emailFormControl.errors.email && this._emailFormControl.dirty;
  }

  get isLoading(){
    return this._loadingHelper.isLoading;
  }

  get errorMessage(){
    return this._errorMessage;
  }

  initForm() {
    this._firstNameFormControl = new FormControl<string>("", { validators: [Validators.required], updateOn: 'blur' });
    this._lastNameFormControl = new FormControl<string>("", { validators: [Validators.required], updateOn: 'blur' });
    this._emailFormControl = new FormControl<string>("", { validators: [Validators.required, Validators.email], updateOn: 'blur' });
    
    this.formGroup = new FormGroup(
      {
        firstName: this._firstNameFormControl,
        lastName: this._lastNameFormControl,
        email: this._emailFormControl
      });
  }


  onRequest() {
    if (this.isLoading) {
      return;
    }

    if (!this.formGroup.valid) {
      this._emailFormControl.markAsDirty();
      this._firstNameFormControl.markAsDirty();
      this._lastNameFormControl.markAsDirty();
      return;
    }

    this._loadingHelper.startLoading();

    var request = new SignatorySignatureRequest();
    request.userId = this.userDetails.userId;
    
    request.tenantId = this.userDetails.organization.tenantId;
    request.groupName = this.group;
    request.signatoryEmail = this._emailFormControl.value;
    request.signatoryFirstName = this._firstNameFormControl.value;
    request.signatoryLastName = this._lastNameFormControl.value;

    this._userService.requestSignatorySignature(request).subscribe({
      next: () => {        
        this.modal.close();        
      },
      error: (error) => {
        this._loadingHelper.stopLoading();
        this._errorMessage = ApplicationError.getMessage(error);
      }
    });
  }

  onAbort() {
    this.modal.close();
  }
}
