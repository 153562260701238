<div id="modal-5" class="c-modal" tabindex="-1" role="dialog">
  <div class="c-modal__overlay"></div>
  <div class="c-modal__wrapper">
    <div class="c-modal__header">
      <button type="button" class="c-modal__close-btn close-5-modal" aria-label="Close" (click)="onAbort()"></button>
    </div>
    <div class="c-modal__content mb-4">
      <header>
        <h3 class="text-start">Begär tillägg till ramavtalet</h3>
      </header>

      <div *ngIf="userDetails !== null && group !== null" class="mb-2">
        <div>
          <span><b>Användare: </b>{{ userDetails.userName }}</span>
        </div>
        <div>
          <span><b>Behörighet: </b>{{ group }} </span>
        </div>
      </div>
      <div class="alert alert-info">
        För att behörigheten ska aktiveras vid inloggning krävs att signering av firmatecknare utförts. Fyll i informationen nedan så återkopplar Fleet Support så snart tillägget till ramavtalet är klart.        
      </div>        
      <form class="mt-2" [formGroup]="formGroup">
        <div class="o-fieldset__row mb-2">
          <label for="firstNameInput">Firmatecknares förnamn*</label>
          <div class="c-input mb-1">
            <input id="firstNameInput" formControlName="firstName" class="c-input__input" type="text" maxlength="255" [class.is-error]="firstNameInvalid" />
          </div>
          <small *ngIf="firstNameInvalid" class="is-error">
            Förnamn måste anges.
          </small>
        </div>
        <div class="o-fieldset__row mb-2">
          <label for="lastNameInput">Firmatecknares efternamn*</label>
          <div class="c-input mb-1">
            <input id="lastNameInput" formControlName="lastName" class="c-input__input" type="text" maxlength="255" [class.is-error]="lastNameInvalid" />
          </div>
          <small *ngIf="lastNameInvalid" class="is-error">
            Efternamn måste anges.
          </small>
        </div>
        <div class="o-fieldset__row mb-2">
          <label for="frameAgreementInput">Firmatecknares e-postadress*</label>
          <div class="c-input mb-1">
            <input id="emailInput" formControlName="email" class="c-input__input" type="text" maxlength="255" [class.is-error]="emailInvalid" />
          </div>
          <small *ngIf="emailRequiredInvalid" class="is-error">
            E-postadress måste anges.
          </small>
          <small *ngIf="emailPatternInvalid" class="is-error">
            E-postadress är inte en giltig e-postadress.
          </small>
        </div>        
      </form>  
      <div *ngIf="errorMessage" class="mt-4">
        <span class="is-error">{{errorMessage}}</span>
      </div>    
    </div>
    <div class="c-modal__footer d-flex justify-content-between">
      <button type="button" class="c-btn c-btn--secondary" (click)="onAbort()">
        <span class="c-btn__text">Avbryt</span>
      </button>
      <button type="button" class="c-btn" aria-label="Close" (click)="onRequest()">        
        <span class="c-btn__text">Begär tillägg</span>
      </button>
    </div>
  </div>
</div>
